body {
  background: #fff;
  font-family: "Poppins", sans-serif !important;
  min-height: 100vh;
}
.footer-section {
  background: url(./assets/images/footer-background.png);
  background-size: 100% 100%;
  width: 100%;
  margin-top: 30px;
}
.radio-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.radio-round {
  display: flex;
  gap: 20px; /* Space between Male and Female */
  align-items: center;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.radio-label input[type="radio"] {
  display: none; /* Hide the default radio button */
}

.custom-radio {
  width: 20px;
  height: 20px;
  border: 2px solid #016d91; /* Blue border color */
  border-radius: 50%; /* Circular radio button */
  display: inline-block;
  margin-right: 8px; /* Space between the circle and label text */
  transition: all 0.3s ease; /* Smooth hover effect */
  position: relative;
}

.radio-label input[type="radio"]:checked + .custom-radio {
  background-color: #0056b3; /* Blue background when checked */
  border-color: #0056b3;
  border-width: 5px; /* Slightly darker blue border when checked */
}

.radio-label input[type="radio"]:checked + .custom-radio::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #ffffff; /* White inner circle */
  border-radius: 50%; /* Makes the inner dot circular */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.radio-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.radio-container label {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  background: #f1f1f1;
  border-radius: 5px;
  color: #626262;
  padding: 8px 26px;
  cursor: pointer;
}
.radio-container > input {
  display: none;
}
.radio-container > input:checked {
  & + label {
    background: #016d91;
    color: #fff;
  }
}
.radio-newcontainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.radio-newcontainer label {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  background: #f1f1f1;
  border-radius: 5px;
  color: #626262;
  padding: 8px 26px;
  cursor: pointer;
}
.radio-newcontainer > input {
  display: none;
}
.radio-newcontainer > input:checked {
  & + label {
    background: #f06f3d;
    color: #fff;
  }
}

.custom_checkbox {
  display: none;
  &_label {
    width: 100%;
    display: inline-block;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 12px 20px;
    text-transform: capitalize;
    color: #2d2c5f;
    font-weight: 500;
  }

  &:checked + label {
    border-color: 2px solid #016d91;
    background-color: #d3ecf1;
  }
}
.custom_newcheckbox {
  display: none;
  &_label {
    width: 100%;
    display: inline-block;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 12px 20px;
    text-transform: capitalize;
    color: #2d2c5f;
    font-weight: 500;
  }

  &:checked + label {
    border-color: 2px solid #f06f3d;
    background-color: #fcc7b2;
  }
}

@media (max-width: 768px) {
  .radio-container label {
    font-size: 14px;
    padding: 8px 20px;
  }
  .radio-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
